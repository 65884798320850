// @flow
import React from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import cs from 'react-intl/locale-data/cs';
import { Consumer } from './context';
import flattenMessages from '%/flattenMessages';
import siteTexts from '../data/siteTexts';

addLocaleData([...cs, ...en]);

type Props = {|
   children: any,
|};

const LangProvider = ({ children }: Props) => (
   <Consumer>
      {({ locale }) => (
         <IntlProvider
            locale={locale}
            messages={flattenMessages(siteTexts[locale])}
         >
            {children}
         </IntlProvider>
      )}
   </Consumer>
);

export default LangProvider;
