// @flow
export default function flattenMessages(
   nestedMessages: {},
   prefix: string = '',
): {} {
   return Object.keys(nestedMessages).reduce((messages, key) => {
      const _messages = messages;
      const value = nestedMessages[key];
      const prefixedKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === 'string') {
         _messages[prefixedKey] = value;
      } else {
         Object.assign(messages, flattenMessages(value, prefixedKey));
      }

      return _messages;
   }, {});
}
