// @flow
export default {
   cs: {
      meta: {
         description:
            'Od roku 1992 se zaměřujeme se na rezidenční i komerční a kancelářské projekty. Stojí za námi desítky úspěšných projektů.',
      },
      header: [
         {
            path: '/',
            label: 'Domů',
         },
         {
            path: '/#about',
            label: 'O nás',
         },
         {
            path: '/#projects',
            label: 'Projekty',
         },
         {
            path: '/#mission',
            label: 'Mise',
         },
         {
            path: '/#contact',
            label: 'Kontakt',
         },
      ],
      landing: {
         heading: 'Stavíme svět <br/> kolem vás',
         cta: 'Naše projekty',
      },
      about: {
         label: 'O nás',
         heading: 'Dáváme lokalitám nový smysl',
         paragraph:
            'Od roku 1992 se zaměřujeme se na rezidenční i komerční a kancelářské projekty. Stojí za námi desítky úspěšných projektů. Kolem některých možná chodíte každý den. Prohlédněte si vybrané realizace.',
      },
      projects: {
         label: 'Naše realizace',
         cta: 'O projektu',
      },
      info: {
         mission: {
            label: 'Naše mise',
            heading: 'Chceme hezčí<br/> Prahu',
            paragraph:
               'Od počátku si vybíráme projekty, kterým můžeme s partnery jako Finep, Sekyra a Karlín Group přinést hodnotu. Stavíme na dlouhodobé reputaci a věříme svému jménu.',
         },
         vision: {
            label: 'Naše vize',
            heading: 'Život zanedbaným<br/> místům',
            paragraph:
               'Praha se rozrůstá do polí, přitom v okolí centra jsou obří nevyužité plochy. Na ty se v aktuálních projektech Smíchov City a Rohan City zaměřujeme a již brzy je proměníme.               ',
         },
      },
      stats: {
         label: 'Aktuální metriky',
         heading: 'Čísla mluví za nás',
         stat1: {
            number: '27',
            label: 'Let zkušeností',
         },
         stat2: {
            number: '150K',
            label: 'm² komerčních prostor',
         },
         stat3: {
            number: '5K',
            label: 'postavených bytů',
         },
      },
      contact: {
         label: 'Kontakt',
         heading: 'Spojte se s námi',
      },
      project: {
         ctaNext: 'Další projekt',
         ctaPrev: 'Předchozí projekt',
      },
      notFound: 'Zdá se, že tato stránka neexistuje.',
   },
   en: {
      meta: {
         description:
            'Od roku 1992 se zaměřujeme se na rezidenční i komerční a kancelářské projekty. Stojí za námi desítky úspěšných projektů.',
      },
      header: [
         {
            path: '/en',
            label: 'Home',
         },
         {
            path: '/en#about',
            label: 'About',
         },
         {
            path: '/en#projects',
            label: 'Projects',
         },
         {
            path: '/en#mission',
            label: 'Mission',
         },
         {
            path: '/en#contact',
            label: 'Contact',
         },
      ],
      landing: {
         heading: 'Shaping the world <br/> around you',
         cta: 'Our developments',
      },
      about: {
         label: 'About us',
         heading: 'Giving places a new meaning',
         paragraph:
            'We have been focusing on residential, commercial and office spaces since 1992. We have successfully finished dozens of projects, some of which you might be passing by every day. Have a look at some of them below.',
      },
      projects: {
         label: 'Our projects',
         cta: 'About',
      },
      info: {
         mission: {
            label: 'Our mission',
            heading: 'Working toward a better Prague',
            paragraph:
               'Since we’ve started some 20 years ago, we have been bringing value to various locations around Prague. Together with our partners Finep, Karlín Group, Star Group or Daramis we are making the city a better place. We have built ourselves a strong reputation and stand behind our work.',
         },
         vision: {
            label: 'Our Vision',
            heading: 'New life for unkempt places',
            paragraph:
               'Prague is sprawling into the surrounding fields while there are vast abandoned areas around Smíchov or Rohanské nábřeží lying idle. We are currently focusing on these brownfields and will change them for the better in the near future.',
         },
      },
      stats: {
         label: 'Current figures',
         heading: 'The numbers speak for themselves',
         stat1: {
            number: '27',
            label: 'years of experience',
         },
         stat2: {
            number: '150K',
            label: 'm² of commercial spaces',
         },
         stat3: {
            number: '5K',
            label: 'flats built',
         },
      },
      contact: {
         label: 'Contacts',
         heading: 'Get in touch',
      },
      project: {
         ctaNext: 'Next project',
         ctaPrev: 'Previous project',
      },
      notFound: 'This page does not appear to exist.',
   },
};
