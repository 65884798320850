// @flow
import { createContext } from 'react';

const ThemeContext = createContext<{
   project: boolean,
   locale: 'cs' | 'en',
}>({
   project: false,
   locale: 'cs',
});

export const { Provider } = ThemeContext;
export const { Consumer } = ThemeContext;
